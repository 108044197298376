import React from 'react'
import ReactDOM from 'react-dom'
import Navigation from '../containers/Navigation';

document.addEventListener('turbolinks:load', () => {
  ReactDOM.render(
    <Navigation />,
    document.getElementsByClassName('burgerMenu')[0]
  )
})
