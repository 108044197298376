export const csrfToken = (document) => {
  return document.querySelector('[name="csrf-token"]').content;
}

export const passCsrfToken = (document, axios) => {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken(document);
}

export const logPlayEvent = (eventName, eventData) => {
  const combinedEventData = {
    'event_category': 'play',
    ...eventData
  };
  gtag('event', eventName, combinedEventData);
};

export const logCreateEvent = (eventName, eventData) => {
  const combinedEventData = {
    'event_category': 'create',
    ...eventData
  };
  gtag('event', eventName, combinedEventData);
};

export const isLoggedIn = () => {
  return window.document.cookie.indexOf('signed_in=1') > -1;
};
