import React from 'react';
import { csrfToken } from '../../../utils/helpers';

const menuItem = (props) => {
  const classes = ['menuItem'];
  if (props.visible === false) {
    classes.push('hidden');
  }
  const token = csrfToken(document);
  const method = props.method ? props.method : 'get';
  const formMethod = method === 'get' ? 'get' : 'post';

  //<a href={props.link} data-method={method} data-turbolinks="false">{props.children}</a>
  return (
  <li className={classes.join(' ')}>
    <form method="post" action={props.link}>
      <input type='hidden' name='authenticity_token' value={token} />
      <input name="_method" value={method} type="hidden" />

      <input type="submit" value={props.children} />
    </form>
  </li>
  );
};

export default menuItem;
