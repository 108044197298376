import React from 'react';
import MenuItem from './MenuItem';

const menu = (props) => {
  const classes = ['menu'];
  if (props.visible === false) {
    classes.push('hidden');
  }
  return (
    <div className={classes.join(' ')}>
      <ul className="menuList">
        <MenuItem link='/'>Home</MenuItem>
        <MenuItem link='/puzzles'>Browse Puzzles</MenuItem>
        <MenuItem link='/create'
          visible={props.isUserLoggedIn}>Create a Puzzle</MenuItem>
        <MenuItem link='/users/sign_out'
          method="delete"
          visible={props.isUserLoggedIn}>Logout</MenuItem>
        <MenuItem link='/users/sign_in'
          visible={props.isUserLoggedIn === false}>Login</MenuItem>
        <MenuItem link='/users/sign_up'
          visible={props.isUserLoggedIn === false}>Create an Account</MenuItem>
      </ul>
    </div>
  );
};

export default menu;
