import React from 'react';

const menuButton = (props) => {
  const iconClassName = props.isOpen ? 'fas fa-times' : 'fas fa-bars';
  return (
    <button className="menuButton" onClick={props.onClick}>
      <i className={iconClassName}></i>
    </button>
  );
};

export default menuButton;
