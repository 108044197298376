import React, { Component } from 'react';
import MenuButton from '../components/Navigation/MenuButton/MenuButton';
import Menu from '../components/Navigation/Menu/Menu';
import { isLoggedIn } from '../utils/helpers';


class Navigation extends Component {

  state = {
    isOpen: false,
    isUserLoggedIn: isLoggedIn()
  };

  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      event.preventDefault();
      event.stopPropagation();
      this.close();
    }
  }

  menuButtonClickHandler = () => { 
    if (this.state.isOpen) {
      this.close();
    } else {
      this.open();
    }
  };

  open = () => {
    document.addEventListener('click', this.handleClickOutside);
    this.setState({isOpen: true});
  };

  close = () => {
    document.removeEventListener('click', this.handleClickOutside);
    this.setState({isOpen: false});
  };

  render() {
    return (
      <div ref={this.setWrapperRef}>
        <MenuButton onClick={this.menuButtonClickHandler} isOpen={this.state.isOpen}>
        </MenuButton>
        <Menu isUserLoggedIn={this.state.isUserLoggedIn}
              visible={this.state.isOpen}>
        </Menu>
      </div>
    );
  }
}

export default Navigation;
